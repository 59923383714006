@import "../../scss/module";

.breadcrumbs {
  padding-top: $spacer * 0.75;
  padding-bottom: $spacer * 0.75;

  nav {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  ol {
    margin: 0;
    flex-wrap: nowrap;

    li {
      white-space: nowrap;
      flex-shrink: 0;

      &:last-of-type {
        a {
          color: inherit;
        }
      }
    }
  }

  a {
    color: var(#{--bs-primary});
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
